.breadcrumb div {
  text-transform: uppercase;
  font-size: 16pt;
  color: var(--primary-color);
  font-weight: bold;
}

.ul {
  margin: 0;
  padding: 0;
  padding-left: 0.25rem;
  list-style: none;
}

.infoTable{
  margin-top: 0.25rem;
}
.tag {
  display: inline-block;
  padding: 0.1em 0.3em;
  background-color: #979797;
  color: #fff;
  border-radius: 3px;
  font-size: 0.9em;
  margin: 0rem 0.5rem;
}
.content {
  flex: 1;
  padding: 1rem;
  min-height: 0;
  min-width: 0;
  display: flex;
  flex-flow: column;
}

.spacerTag{
  visibility: hidden;
}