.container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

.itemContainer {
  display: flex;
  box-shadow: rgb(50 50 93 / 25%) 0px 13px 27px -5px, rgb(0 0 0 / 30%) 0px 8px 16px -8px;
  background: white;
  color: black;
  border: 1px solid var(--primary-color);
  margin: 0.5rem;
}

.icon {
  font-size: 18pt;
  color: white;
  background: var(--primary-color);
  padding: 0.25rem;
  align-self: stretch;
  width: 2rem;
}

.nameContainer {
  display: flex;
  flex-flow: column;
  flex: 1;
  padding: 0.25rem;
}

.name {
  font-weight: bold;
  color: var(--primary-color);
}
