.badgeContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  background-color: #dbdbdb;
  text-align: center;
  margin-left: 0.25rem;
  border-radius: 20px;
  transition: background-color 0.5s linear;
}

.badgeContainer.active {
  background-color: var(--highlight-color);
}
.badge {
  display: block;
  color: white;
  font-size: 7pt;
  font-weight: bold;
}
