.container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-auto-rows: 1fr;
}

.itemContainer {
  display: flex;
  margin: 0.5rem;
  color: gray;
  background: rgb(251 251 251);
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  border: 1px solid transparent;
}

.icon {
  font-size: 18pt;

  padding: 0.25rem;
  align-self: stretch;
  width: 2rem;
}

.selected .icon {
  color: white;
  background: var(--primary-color);
}

.nameContainer {
  display: flex;
  flex-flow: column;
  flex: 1;
  padding: 0.25rem;
}

.name {
  font-weight: bold;
}

.selected .name {
  color: var(--primary-color);
}

.toggle {
  padding: 0.25rem;
}

.selected {
  box-shadow: rgb(50 50 93 / 25%) 0px 13px 27px -5px, rgb(0 0 0 / 30%) 0px 8px 16px -8px;
  background: white;
  color: black;
  border: 1px solid var(--primary-color);
}

.groupKey {
  text-transform: uppercase;
  color: var(--primary-color);
  font-weight: bold;
  margin-top: 1rem;
}
