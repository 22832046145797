.content {
  display: flex;
  flex-flow: column;
  flex: 1;
  min-width: 0;
  min-height: 0;
  padding: 1rem;
}

.filterCard {
  margin-bottom: 1rem;
}

.filterCard_content {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 0.5rem;
}
.resultCard {
  flex: 1;
  display: flex;
  flex-flow: column;
  min-width: 0;
  min-height: 0;
}

.commandCard {
  margin-bottom: 2rem;
}