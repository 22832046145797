.description {
  width: 7rem;
  font-weight: bold;
}
.value {
  min-width: 8rem;
}

.table {
  table-layout: fixed;
  width: 100%;
}

/*   width="100%" */
