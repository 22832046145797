.notification {
  padding: 2 px 5 px;
  background: #fff;
  border: solid 1 px #e1e4e5;
  color: #333;
  white-space: pre-wrap;
  word-wrap: break-word;
}

.timestampDisplay {
  margin-top: 0.25rem;
}
.timestampDisplay_time {
  margin-right: 0.25rem;
}

.timestampDisplay_indicator {
  background: orange;
  color: white;
  padding: 2px;
}
