#root {
  flex: 1;
  display: flex;
  flex-flow: column;
  min-height: 0;
  min-width: 0;
}

.appHeader {
  display: flex;
  flex-flow: column;
  width: 18rem;
  padding: 10px;
  background: #ffffff;
  box-shadow: 0 1.6px 3.6px 0 rgb(0 0 0 / 13%), 0 0.3px 0.9px 0 rgb(0 0 0 / 11%);
}

.nav {
  flex: 1;
  margin-top: 2rem;
}

.nav_footer {
  display: flex;
  flex-flow: column;
}

.nav_language {
  display: flex;
  align-items: center;
  border-top: 1px solid var(--primary-color);
  margin-top: 1rem;
}

.nav_language_wrapper {
  flex: 1;
  margin-top: 0.25rem;
}

.nav_link {
  display: flex;
  align-items: center;
  font-size: 12pt;
  text-decoration: none;
  text-transform: uppercase;
  font-weight: bold;
  color: var(--primary-color);
  margin-bottom: 1rem;
  padding: 0.25rem;
  border-left: 4px solid transparent;
}

.nav_link_icon {
  margin-right: 0.5rem;
}

.nav_link:hover {
  border-left-color: var(--primary-color-lighter);
}

.nav_link.active {
  border-left-color: var(--primary-color);
}

.maincontainer {
  flex: 1;
  display: flex;
  /* flex-flow: column; */
  min-height: 0;
  min-width: 0;
  overflow: hidden;
  /* padding: 1rem; */
  background: rgb(251 251 251);
}

.maincontent {
  flex: 1;
  display: flex;
  flex-flow: column;
  min-height: 0;
  min-width: 0;
  overflow: auto;
}

.headerImage {
  height: 5.25rem;
}

.environment {
  text-align: center;
  display: block;
  text-transform: uppercase;
  margin-top: 1rem;
  padding: 0.25rem;
  background-color: var(--primary-color);
  font-weight: bold;
  color: #ffffff;
}

.appversion {
  color: var(--primary-color);
  margin-top: 0.25rem;
}
