.container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

.itemContainer {
  display: flex;
  color: gray;
  background: rgb(251 251 251);
  margin: 0.5rem;
  align-items: center;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  border: 1px solid transparent;
}

.icon {
  padding: 0.25rem;
  font-size: 16pt;
}

.name {
  flex: 1;
  padding: 0 1rem;
  font-size: 12pt;
  padding: 0.25rem;
}

.indicator {
  font-size: 16pt;
  padding: 0.25rem;
  width: 2rem;
}

.available .indicator {
  background: var(--primary-color);
  color: white;
}

.available {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
  background: white;
  color: black;
  border: 1px solid var(--primary-color);
}
