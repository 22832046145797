:root {
  --background: #f7f8fa;
  --card-header-color: #656565;
  --primary-color: #555e62;
  --primary-color-lighter: #7a858b;
  --highlight-color: orange;
}

* {
  box-sizing: border-box;
}

html,
body {
  height: 100%;
  width: 100%;
  padding: 0;
  margin: 0;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: var(--background);
  display: flex;
  overflow: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.themeContainer {
  display: flex;
  flex-flow: column;
  flex: 1;
  min-height: 0;
  min-width: 0;
}
