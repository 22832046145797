.silver {
    background-color: #c0c4c5;
}

.gold {
    background-color: #c6a761;
}

.platinum {
    background-color: #545d61;
}

.error {
    background-color: rgb(180, 45, 45);
}

.default {}