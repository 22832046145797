.card {
  width: 100%;
  background: #ffffff;
  box-shadow: 0 1.6px 3.6px 0 rgb(0 0 0 / 13%), 0 0.3px 0.9px 0 rgb(0 0 0 / 11%);
}

.header {
  font-weight: bold;
  font-size: 12pt;
  padding: 10px;
  text-transform: uppercase;
  color: var(--card-header-color);
}

.content {
  display: flex;
  flex-flow: column;
  align-items: stretch;
  position: relative;
  padding: 10px;
  flex: 1;
  min-width: 0;
  min-height: 0;
  overflow: hidden;
}

.collapsible {
  transition: max-height 0.1s linear, opacity 0.15s linear;
  max-height: infinite px;
  padding-left: 0.25rem;
  opacity: 100;
}

.collapsed {
  transition: max-height 0.1s linear, opacity 0.15s linear;
  max-height: 0;
  opacity: 0;
  padding-left: 0.25rem;
}

.rotateDown {
  animation: rotationDown 0.15s linear;
}

.rotateRight {
  animation: rotationRight 0.15s linear;
}

@keyframes rotationDown {
  from {
      transform: rotate(90deg);
  }

  to {
      transform: rotate(0deg);
  }
}

@keyframes rotationRight {
  from {
      transform: rotate(-90deg);
  }

  to {
      transform: rotate(0deg);
  }
}

.animation {
  height: auto;
  max-height: 100%;
  overflow: hidden;
  padding-left: 0.25rem;
  border-color: black;
  border: 1px;
}
