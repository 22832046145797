.content {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: column;
    flex: 1;
    min-width: 0;
    min-height: 0;
    padding: 1rem;
}

.hero {
    color: var(--primary-color);
    font-size: 24pt;
    font-weight: bold;
}

.message {
    font-size: 16pt;
}

.contact {
    font-size: 16pt;
    margin-top: 1rem;
}

