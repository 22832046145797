.content {
  flex: 1;
  padding: 1rem;
  min-height: 0;
  min-width: 0;
  display: flex;
  flex-flow: column;
}

.commandCard {
  margin-bottom: 2rem;
}

.formContent {
  overflow: auto;
  padding: 0.25rem 0;
}

.formWrapper {
  display: grid;
  max-width: 50rem;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1rem;
}
