.flexItem{
    min-width: 20rem;
    max-width: 20rem;
    max-height: 30rem;
    overflow-y: auto;
  }
.contractContainer{
    border-style: solid;
    border-color: #818080;
    margin-bottom: 1rem;
}