.content {
  flex: 1;
  min-width: 0;
  min-height: 0;
  padding: 1rem;
  overflow-y: auto;
}

.lastActivitiesCardContent {
  height: 20rem;
  overflow-y: auto;
}

.actionsContainer {
  display: grid;
  grid-auto-columns: 15rem;
  grid-gap: 0.5rem;
  grid-auto-flow: column;
  overflow-x: auto;
}
